
import store from "@/store";
import conf from './MainConf'
import {Base64} from 'js-base64'

export default {
	state: {
		currentTabIndex: conf.homeTab.menuId, // 当前显示的 tab
		homeTabMenuId: conf.homeTab.menuId,   // 主页 tab 的 menuId
		openedTabs: [                         // 当前打开的 tab 列表
			conf.homeTab
		],
		tabBarInfo:{
			tabBarWidth:701,
			tabBarHeight:601,
			isPc:false,
		},
		refrshlist:{
			PrmitlistRefr:false,
			OrderlistRefr:false,
		},
		hascall:0,
		mobileAuto:[],
	},
	getters: {
		GettabBarInfo (state) {
			return function (item) {
				return state.tabBarInfo[item]
			}
		},
		GettabBarWH (state) {
				return state.tabBarInfo.tabBarWidth+state.tabBarInfo.tabBarHeight
		},
		GetRefrshItem (state) {
			return function (item) {
				return state.refrshlist[item]
			}
		},
		GetHasCall (state) {
			return state.hascall
		},
		GetmobileAuto(state){
			return state.mobileAuto
		},
		// 获取 当前显示的 tab name
		GetCurrentTabIndex (state) {
			return state.currentTabIndex
		},
		GetQuestionUrl (state) {
			return state.questionpara
		},
		// 获取 主页 tab 的 menuId
		GetHomeTabMenuId (state) {
			return state.homeTabMenuId
		},
		// 获取 当前打开的 tab 列表
		GetOpenedTabs (state) {
			return state.openedTabs
		},
		// 获取查询参数
		GetQuery (state) {
			let res = Object.create(null)
			state.openedTabs.map(i => {
				if (i.menuId === state.currentTabIndex) {
					let end = i.components.length - 1
					res = i.components[end].query || {}
					return
				}
			})
			return res
		},
		// 获取当前激活的 tab
		GetCurrentTab (state) {
			
			return state.openedTabs.find(i => i.menuId === state.currentTabIndex)
		}
	},
	mutations: {
		SetRefrshItem (state,item) {
			state.refrshlist[item]=!state.refrshlist[item];
		},
		SetHasCall (state,item) {
			state.hascall=item;
		},
		SettabBarWH (state,item) {
			state.tabBarInfo.tabBarWidth = item[0];
			state.tabBarInfo.tabBarHeight = item[1];
			state.tabBarInfo.isPc = item[2];
		},
		SetmobileAuto (state,item) {
			if (state.mobileAuto.length>6){
				state.mobileAuto.pop();
			}
			state.mobileAuto.unshift({"value":item})
		},
		// 设置 hash
		SetHash (state) {
			let cur = state.openedTabs.find(i => i.menuId === state.currentTabIndex)
			//console.log(cur)
			location.hash =  Base64.encode(JSON.stringify(cur))
			//console.log(location.hash)
		},
		// 设置 主页 tab
		SetHomeTab (state,item) {
			state.homeTabMenuId = item.menuId
			state.openedTabs[0] = item
		},
		// 回显 tab
		reShowHash (state) {
			let url = location.href
			let indexOfSharp = url.indexOf('#')
			if (indexOfSharp > 0 && url.length>indexOfSharp+10 && url.substr(indexOfSharp + 1,1)==="/") {
				indexOfSharp =indexOfSharp +1
			}
			if (indexOfSharp > 0 && url.length>indexOfSharp+10) {
				let hash = url.substr(indexOfSharp + 1)
				//console.log("请求DATA",Base64.decode(hash));
				let tab = JSON.parse(Base64.decode(hash))
				// let tab = JSON.parse(hash)
				if (tab.menuId === state.homeTabMenuId) {
					state.openedTabs = state.openedTabs.filter(i => i.menuId !== state.homeTabMenuId)
				}
				// if (state.openedTabs.findIndex((c)=>{
				// 	c.menuId=tab.menuId
				// })==-1){
				// 	console.log("tab")
				// }
				// console.log(tab)
				state.openedTabs.push(tab)
				state.currentTabIndex = tab.menuId
			}
			
		},
		// 设置 当前显示的 tab name
		SetCurrentTabIndex (state, data) {
			if (state.currentTabIndex === data) return
			state.currentTabIndex = data
			//console.log("SetCurrentTabIndex")
			store.commit('SetHash')
		},
		// 添加 tab 到 tab 列表
		OpenedTabsPush (state, item) {
			if (state.currentTabIndex === item.menuId) return
			// 设置当前要显示的 tab name
			state.currentTabIndex = item.menuId

			// 判断 tab 项是否已存在
			let tabExsit = state.openedTabs.find(i => i.menuId === item.menuId)
			if (!tabExsit) {
				state.openedTabs.push({
					title: item.title,                      // 显示标题
					menuId: item.menuId,                    // 用于标记当前打开 tab 的 name
					components: [{path: item.component}],   //  tab 对应的组件
				})
			} else if(item.fromHistory) {               // 点击前进后退按钮
				state.openedTabs.map(i => {
					if(i.menuId === item.menuId) {
						i.components = item.components
					}
				})
			}
			//console.log("OpenedTabsPush")
			if (!item.fromHash) store.commit('SetHash')
		},
		// 当前 tab 内部的跳转
		OpenedSubTabsPush (state, item) {
			state.openedTabs.map(i => {
				if (i.menuId === state.currentTabIndex) {
					i.components.push(item)
				}
			})
			//console.log("OpenedSubTabsPush")
			store.commit('SetHash')
		},
		// 返回
		OpenedSubTabsBack (state, num = 1) {
			if (num < 1) num = 1
			state.openedTabs.map(i => {
				if (i.menuId === state.currentTabIndex) {
					let newLength = i.components.length - ~~num
					if (newLength > 0) {
						i.components = i.components.slice(0, newLength)
					}
				}
			})
			//console.log("OpenedSubTabsBack")
			store.commit('SetHash')
		},
		// 替换当前组件
		OpenedSubTabsReplace (state, item) {
			let index = state.openedTabs.length - 2
			state.openedTabs.map(i => {
				if (i.menuId === state.currentTabIndex) {
					i.components.splice(index, 1, item)
				}
			})
			//console.log("OpenedSubTabsReplace")
			store.commit('SetHash')
		},
		// 从 tab 列表 移除 tab
		OpenedTabsRemove (state, menuId) {
			state.openedTabs = state.openedTabs.filter(item => {
				return item.menuId === state.homeTabMenuId || item.menuId !== menuId
			})

			// 查询当前标签是否被关闭，如果被关闭，则打开主页标签
			let tab = state.openedTabs.find(item => item.menuId === menuId)
			if (!tab) state.currentTabIndex = state.homeTabMenuId
			//console.log("OpenedTabsRemove")
			store.commit('SetHash')
		},
		// 关闭 其他的 tab
		CloseOthersTabs (state) {
			state.openedTabs = state.openedTabs.filter(item => {
				return item.menuId === state.homeTabMenuId || item.menuId === state.currentTabIndex
			})
			//console.log("CloseOthersTabs")
			store.commit('SetHash')
		},
		// 关闭所有 tab
		CloseAllTabs (state) {
			state.openedTabs.length = 1
			state.currentTabIndex = state.homeTabMenuId
			//console.log("CloseAllTabs")
			store.commit('SetHash')
		},

	},
	actions: {},
}
