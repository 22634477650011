// import Vue from "vue";

const route = Object.create(null)
route.install = function (vue) {
  // 第一个字符串是 组件名，第二个是组件路径，第三个是包名(如果不指定则已1.js,2.js....n.js命名)
	vue.component('home', (resolve) => {require.ensure([], ()=>resolve(require('@/views/home.vue')), 'home')})
	vue.component('hello2', (resolve) => {require.ensure([], ()=>resolve(require('@/views/hello2.vue')), 'hello2')})
	vue.component('gasstation', (resolve) => {require.ensure([], ()=>resolve(require('@/views/companyprofile/gasstation.vue')), 'gasstation')})
	vue.component('gassafe', (resolve) => {require.ensure([], ()=>resolve(require('@/views/companyprofile/gassafe.vue')), 'gassafe')})
	vue.component('gasorder', (resolve) => {require.ensure([], ()=>resolve(require('@/views/companyprofile/gasorder.vue')), 'gasorder')})
	vue.component('myinfo', (resolve) => {require.ensure([], ()=>resolve(require('@/views/companyprofile/myinfo.vue')), 'myinfo')})
	vue.component('myorder', (resolve) => {require.ensure([], ()=>resolve(require('@/views/companyprofile/myorder.vue')), 'myorder')})	
	vue.component('worklocation', (resolve) => {require.ensure([], ()=>resolve(require('@/views/companyprofile/worklocation.vue')), 'worklocation')})	
	vue.component('callsystem', (resolve) => {require.ensure([], ()=>resolve(require('@/views/callcenter/callsystem.vue')), 'callsystem')})	
	vue.component('custmodify', (resolve) => {require.ensure([], ()=>resolve(require('@/views/callcenter/custmodify.vue')), 'custmodify')})	
	vue.component('infosetup', (resolve) => {require.ensure([], ()=>resolve(require('@/views/callcenter/infosetup.vue')), 'infosetup')})	
	vue.component('scenedata', (resolve) => {require.ensure([], ()=>resolve(require('@/views/scenechart/scenedata.vue')), 'scenedata')})	
	vue.component('workstrack', (resolve) => {require.ensure([], ()=>resolve(require('@/views/scenechart/workstrack.vue')), 'workstrack')})	
	vue.component('gasinfo1', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/gasinfo1.vue')), 'gasinfo1')})	
	vue.component('overhaul2', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/overhaul2.vue')), 'overhaul2')})	
	vue.component('sale4', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/sale4.vue')), 'sale4')})	
	vue.component('emptygass5', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/emptygass5.vue')), 'emptygass5')})	
	vue.component('customer6', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/customer6.vue')), 'customer6')})	
	vue.component('upload8', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/upload8.vue')), 'upload8')})	
	vue.component('security9', (resolve) => {require.ensure([], ()=>resolve(require('@/views/reports/security9.vue')), 'security9')})	
	
	
}


export default route