export default {
	// 列表配置
	// 菜单列表
	menu: [
		{
			title: '瀛海简介',            // 一级菜单名称
			icon: 'el-icon-office-building',   // 一级菜单 icon
			menuId: '1',                // 索引(必须唯一，是字符串)
			sub: [                        // 子菜单(二级菜单)
				{
					title: '配送站点',      // 菜单名称
					component: 'gasstation',    // 点击菜单打开的组件名称
					menuId: '1-1'           // 索引 (用于识别当前打开的tab,必须唯一，是字符串)
				},
				{
					title: '安全用气', component: 'gassafe', menuId: '1-2'
				},
			]
		},

		{
			title: '预约订气',
			icon: 'el-icon-shopping-cart-1',
			menuId: '2',
			sub: [
				{ title: '我的资料', component: 'myinfo', menuId: '2-1' },
				{ title: '我要预约', component: 'gasorder', menuId: '2-2'},
				{ title: '预约订单', component: 'myorder', menuId: '2-3' },
			]
		},

		{
			title: '呼叫中心',
			icon: 'el-icon-service',
			menuId: '3',
			sub: [
				{ title: '预约接单', component: 'callsystem', menuId: '3-1' },
				{ title: '客户维护', component: 'custmodify', menuId: '3-2' },
				{ title: '信息设置', component: 'infosetup', menuId: '3-3' },
			]
		},
		{
			title: '综合查询',
			icon: 'el-icon-document',
			menuId: '4',
			sub: [
				{ title: '气瓶档案', component: 'gasinfo1', menuId: '4-1' },
				{ title: '调拨入库', component: 'overhaul2', menuId: '4-2' },
				{ title: '站点销售', component: 'sale4', menuId: '4-3' },
				{ title: '空瓶回厂', component: 'emptygass5', menuId: '4-4' },
				{ title: '客户统计', component: 'customer6', menuId: '4-7' },
				{ title: '上报统计', component: 'upload8', menuId: '4-8' },
				{ title: '安检统计', component: 'security9', menuId: '4-9' },
			]
		},
		{
			title: '实时概览',
			icon: 'el-icon-location',
			menuId: '5',
			sub: [
				{ title: '实时数据', component: 'scenedata', menuId: '5-1' },
				{ title: '运输轨迹', component: 'workstrack', menuId: '5-2' },
			]
		},
		{
			title: '数据分析',
			icon: 'si-elusive-th-list',
			menuId: '6',
			sub: [
				{ title: '业绩分析', component: 'PerformanceAnalysis', menuId: '6-1' },
				{ title: '商品分析', component: 'InventoryAnalysis', menuId: '6-2' },
				{ title: '时段消费', component: 'PeriodAnalysis', menuId: '6-3' },
			]
		},
		{
			title: '用户管理',
			icon: 'si-icomoon-user',
			menuId: '7',
			sub: [
				{ title: '用户信息', component: 'UserInfoList', menuId: '7-1' }
			]
		},
		{
			title: '操作员管理',
			icon: 'si-icomoon-users',
			menuId: '40',
			// sub: [
			// 	{title: '子菜单4-2', component: 'hello2', menuId: '40-2'},
			// 	{title: '子菜单4-3', component: 'hello3', menuId: '40-3'}
			// ]
		},
	],
	// 收藏列表  字段说明同 [菜单列表]
	// favor: [
	// 	{title: '子菜单1-1', component: 'hello1', menuId: '1-1'}
	// ],
	// 主页 tab 配置
	homeTab: {
		title: '首页',                     //  tab 显示标题
		menuId: 'home',                   //  tab 内部名称(用来识别当前打开的tab)
		components: [{ path: 'home' }],     //  tab 对应的组件
	}
}
