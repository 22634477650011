import Vue from 'vue'
//import App from './App.vue'
import store from './store'
import router from './router'
//import vuetify from './plugins/vuetify';


import TabRoute from './router/TabRoute';
import RouterMethods from './router/RouterMethods';
Vue.use(TabRoute)
Vue.use(RouterMethods)
import api from './Api/networking';
Vue.prototype.$api = api
import MainFrame from '@/Frame/MainFrame.vue';
//import installElementPlus from './plugins/element'
//import MainFrame from '@/views/hello2.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '8ktYGStGratzruLRGVenOKpM7gAn39d6'
})
Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  el: '#app',
  store,
  //vuetify,
  render: h => h(MainFrame)
})
