// 引用axios
var axios = require('axios')
var CryptoJS = require("crypto-js/core")
var MD5 = require("crypto-js/md5")
//let url1 = location.href
//console.log("url1.host",location.host)
//var baseUrl = (url1.indexOf("192.168.1")>1 ? 'http://192.168.1.51:8080/yhweb/yhwebqueryapi.aspx' : 'http://www.yh59481717.com/yhweb/yhwebqueryapi.aspx')
//var smsUrl = (url1.indexOf("192.168.1")>1 ? 'http://192.168.1.51:8080/yhweb/yhwebsmsapi.aspx' : 'http://www.yh59481717.com/yhweb/yhwebsmsapi.aspx')
let url1 =location.protocol+"//" +location.host
if (url1.indexOf("localhost")>1)
	url1="http://www.yh59481717.com"
//console.log("url1.host",location.protocol,location.host,url1)
var baseUrl =url1+"/yhweb/yhwebqueryapi.aspx"
var smsUrl = url1+"/yhweb/yhwebsmsapi.aspx"

var qs = require('qs')
//解决chrome 跨域问题
//"C:\Program Files (x86)\Google\Chrome\Application\chrome.exe" --user-data-dir="c:\ChromeDebug" --test-type --disable-web-security
// 封装axios的get方法post方法put方法等
// 自定义判断元素类型JSs
function toType(obj) {
	return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull(parameters) {
	for (let key in parameters) {
		//console.log(toType(parameters[key]));
		if (parameters[key] === null || (toType(parameters[key]) == 'string' && parameters[key].trim() === '')) {
			delete parameters[key];
		}
		switch (toType(parameters[key])) {
			case 'string':
				parameters[key] = parameters[key].replace('\'', '').replace("'", '').trim();
				break;
			case 'object':
				parameters[key] = filterNull(parameters[key]);
				break;
			case 'array':
				parameters[key] = filterNull(parameters[key]);
				break;
		}
	}
	return parameters;
}

// 网络请求深层封装
function httpService(method, urlStr, params, success, failure) {
	if (!params.tokencode) {
		let tokencode=sessionStorage.getItem("userToken") || ""
		params.tokencode=(!tokencode || tokencode=="undefined") ? 'absaadqrqrffg2235ryuryu43' : tokencode}
	var parameters = filterNull(params);
	var curl = (parameters.lx=="registermobile" || parameters.lx=="resetpassword" || parameters.lx=="getverifycode") ? smsUrl : baseUrl
	console.log("请求DATA",parameters,curl + urlStr,params.tokencode);
	axios({
		method: method, // 默认
		url: null,
		baseURL: curl + urlStr,
		headers: { 'APITYPE': 'REQUESTS', 'Content-Type': 'application/json;text/xml' },
		data: method === 'POST' || method === 'PUT' ? JSON.stringify(parameters) : null,
		params: method === 'GET' || method === 'DELETE' ? JSON.stringify(parameters) : null,
		cache: false,
		withCredentials: false, // default
		responseType: 'json', // default
	})
		.then(function (response) {
			let data=response.data
			console.log("返回结果",parameters.lx,data);
			success(data)
		})
		.catch(function (error) {
			console.log("错误结果",error);
			failure(error)
		});

}
function getTimeStamp() {
	var timestamp = Date.parse(new Date()) / 1000;
	return '' + timestamp;
}
function formatJson(filterVal, jsonData) {
	return jsonData.map(v => filterVal.map(j => v[j]))
}
function getCookie(name) {
	var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
	arr = document.cookie.match(reg);
	if (arr)
		return (arr[2]);
	else
		return null;
}
export default {
	get: function (url, params, success, failure) {
		return httpService('GET', url, params, success, failure)
	},
	post: function (url, params, success, failure) {
		return httpService('POST', url, params, success, failure)
	},
	posttest: function (url) {
		//console.log(qs.stringify(url))
		return url
	},
	put: function (url, params, success, failure) {
		return httpService('PUT', url, params, success, failure)
	},
	delete: function (url, params, success, failure) {
		return httpService('DELETE', url, params, success, failure)
	},
	filterStr: function (str) {
		if (str == null) return '';
		var pattern = new RegExp("[`~!@#$^&*()=|{}':',\\[\\]<>/?~！@#￥……&*（）——|{}【】‘：”“'。，、？%+_]");
		var specialStr = "";
		for (var i = 0; i < str.length; i++) {
			specialStr += str.substr(i, 1).replace(pattern, '');
		}
		return specialStr;
	},
	myFilter: function (str) {
		if (str == null) return '';
		return str.split(/["'“”‘’“{}]/).join("").replace(/\n/g, '').replace(' ', '').replace('[', '').replace(']', '').trim();
	},
	myFilterno: function (str) {
		if (str == null) return '';
		return str.split(/["'“”‘’“{}]/).join("").replace(/\n/g, '').replace('[', '').replace(']', '').trim();
	},
	getSelectorOptions: function (dataArray, keyNamePrefix) {
		if (dataArray == null || dataArray.length <= 0) {
			return [];
		}
		var result = [];
		for (var index = 0; index < dataArray.length; index++) {
			var tempDict = {
				'id': keyNamePrefix + index,
				'value': dataArray[index]
			};
			result.push(tempDict);
		}
		return result;
	},
	export2Excel: function (headers, filterVal, dataArray, fileName) {
		require.ensure([], () => {
			const { export_json_to_excel } = require('../Api/export2Excel');
			console.log("开始制作。。。。")
			const data = formatJson(filterVal, dataArray);
			//console.log(data)
			export_json_to_excel(headers, data, fileName);
		});
	},
	getLocalTime: function (nS) {
		return new Date(parseInt(nS) * 1000).toLocaleString().replace('/年|月/g', '/').replace('/日/g', '/');
	},

	formatDate: function (date) {
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		m = m < 10 ? '0' + m : m;
		var d = date.getDate();
		d = d < 10 ? ('0' + d) : d;
		return y + '-' + m + '-' + d;
	},
	formatTime: function (time) {
		//console.log(new Date(time))
		var date=new Date(time)
		var y = date.getFullYear();
		var m = date.getMonth() + 1;
		m = m < 10 ? '0' + m : m;
		var d = date.getDate();
		d = d < 10 ? ('0' + d) : d;
		var h = date.getHours();
		h = h < 10 ? ('0' + h) : d;
		var n = date.getMinutes();
		n = n < 10 ? ('0' + n) : d;
		var s = date.getSeconds();
		s = s < 10 ? ('0' + s) : d;
		return y + '-' + m + '-' + d + ' ' + h + ':' + n + ':' + s;
	},
	checkMobile: function (phone) {
		let res = false;
		if (phone && (/^1[0-9]\d{9}$/.test(phone) || /^[1-9]\d{7}$/.test(phone))) {
			res = true;
		}
		return res;
	},
	getUrlKey: function (url,key) {
		return decodeURIComponent((new RegExp('[?|&]'+key+'='+'([^&;]+?)(&|#|;|$)').exec(url)||[""])[1]) || null;
	},
	getCookie: function (name) {
		return getCookie(name);
	},
	setCookie: function (c_name, value, expiredays) {
		var exdate = new Date();
		exdate.setDate(exdate.getDate() + expiredays);
		document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
	},
	//删除cookie
	delCookie: function  (name) {
		var exp = new Date();
		exp.setTime(exp.getTime() - 1);
		var cval = getCookie(name);
		if (cval != null)
		document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
	},
}