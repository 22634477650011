<template>
  <div v-if="LoginInfo.Logined== 'No'" class="loginBox" >
    <el-card class="login">
      <div slot="header" class="clearfix">
        <div style="padding: 15px 15px 0px 15px">
          <span style="float: left">{{
            isScanLogin ? "扫码登陆" : "账号登陆"
          }}</span>
          <el-button
            style="float: right; padding: 3px 0"
            size1="medium"
            type="text"
            @click="changeLogin"
            >{{ isScanLogin ? "账号登陆" : "扫码登陆" }}</el-button
          >
        </div>
      </div>
      <div v-if="!isScanLogin">
        <el-form ref="LoginInfo" :model="LoginInfo" label-width="0px">
          <el-form-item>
            <el-input
              class="input"
              placeholder="输入手机号"
              size="medium" maxlength="11"
              prefix-icon="el-icon-mobile-phone"
              v-model="Mobile" clearable
            >
            </el-input>
            <el-button
              class="button"
              type="success"
              size="medium"
              plain
              @click="regMobile"
              >我要注册</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-input class="input" size="medium" placeholder="密码或验证码" prefix-icon="el-icon-edit"
              v-model="LoginInfo.vcode" show-password clearable maxlength="8"
            >
            </el-input>
            <el-button class="button" type="success" size="medium" plain
              @click="restPwd">重置密码</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button style="width: 310px;margin-top:20px;" type="primary" @click="beginLogin"
              >登 陆</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-else>
        <canvas id="canvas" width="150rpx" height="150rpx"></canvas>
				<div style="margin-bottom: 20rpx;line-height: 40rpx;" >打开微信扫一扫，扫描二维码登录</div>
      </div>
      <div><a style="text-decoration:none;" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备17020324号-1</a></div>
    </el-card>
  </div>
  <div v-else-if="LoginInfo.Logined== 'Yes'">
    <div class="indexWrap">
      <!--头部栏 开始-->
      <header class="topWrap">
        <!--顶部栏 左侧-->
        <div class="dsf">
          <!--logo 开始-->
          <div class="topLogo">
            <img class="topLogo-img" src="@/Images/logo3.png" />
            <div class="topLogoText"></div>
          </div>
          <!--logo 结束-->

          <!--左侧 隐藏菜单按钮 开始-->
          <div class="appName" v-if="!fullScreen" @click="showHideMenu()">
            <i
              style="font-size: 24px"
              :class="
                !isPc
                  ? 'el-icon-menu'
                  : showMenu
                  ? 'el-icon-d-arrow-left'
                  : 'el-icon-d-arrow-right'
              "
            ></i>
          </div>
          <!--公司名称-->
          <div
            @click="isPc = !isPc"
            class="currentProjectName"
            v-if="isWideScreen"
          >
            上海瀛海燃气有限责任公司
          </div>
        </div>
        <!--左侧 隐藏菜单按钮 结束-->
        <!--顶部栏 右侧-->
        <div class="dsf">
          <!--用户信息-->

          <div class="userInfo">
            <el-dropdown placement="bottom">
              <span class="mr10 dsf aic cfff">
                <!-- <img src="@/Images/default.jpg" class="avatar" /> -->
                <el-avatar :size="50" :src="LoginInfo.avatarUrl">
                  <img src="@/Images/default.jpg"/>
                </el-avatar>
                {{ LoginInfo.OperatorMan }}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="ExitLogin"
                  >退出登陆</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </header>
      <!--头部栏 结束-->
      <!--主框架 开始-->
      <div :class="fullScreen ? 'middleWrap' : 'middleWrap'" >
        <!--左侧 开始-->
        <transition :name="isPc ? 'left' : 'fleft'">
          <div :class="isPc ? 'left' : 'fleft'" v-if="showMenu && !fullScreen">
            <!--搜索和收藏 开始-->
            <header class="searchAndFavor">
              <el-input
                placeholder="请输入内容"
                size="mini"
                v-model="searchText"
              >
                <i class="el-icon-search searchiIcon" slot="suffix"></i>
              </el-input>
              <div class="myFavor">
                <el-dropdown placement="bottom">
                  <i class="el-icon-star-on myFavorIcon"></i>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item disabled>收藏夹</el-dropdown-item>

                    <el-dropdown-item
                      v-for="(item, index) in favorite"
                      :key="index"
                      :divided="index === 0"
                    >
                      <span @click="openTab(item)">{{ item.title }}</span>
                      <i
                        class="el-icon-close ml10"
                        @click="removeFavorite(item)"
                      ></i>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </header>
            <!--搜索和收藏 结束-->

            <!--左侧主菜单 开始-->
            <el-menu
              :default-active="currentTabIndex"
              :default-openeds="spreadedMenus"
              active-text-color="#ffd04b"
              class="el-menu-vertical-demo"
            >
              <el-submenu
                v-for="(item, index) in menuList"
                :key="index"
                :index="item.menuId"
              >
                <template slot="title">
                  <i :class="item.icon"></i>
                  <!-- <svg class="svgMenu">
                    <use :xlink:href="'svg/SvgIcon.svg#' + item.icon"></use>
                  </svg> -->
                  <span>{{ item.title }}</span>
                  
                </template>

                <el-menu-item
                  v-for="(subItem, subIndex) in item.sub"
                  :key="subIndex"
                  @click="openTab(subItem)"
                  :index="subItem.menuId"
                  style="padding-left: 60px !important"
                >{{ subItem.title }}
                  
                  
                </el-menu-item>
              </el-submenu>
            </el-menu>
            <!--左侧主菜单 结束-->
          </div>
        </transition>
        <!--左侧 结束-->
        <!--右侧 开始-->
        <div :class="fullScreen ? 'fullRight' : 'right'" ref="rightp">
          <!--打开的菜单(tab-bar) 开始fullRight-->
          <div class="tabWrap">
            <div class="tabBar" ref="tabBar">
              <el-tabs
                :value="currentTabIndex"
                @input="$tab.showTab"
                type="border-card" 
                @tab-remove="$tab.close"
              >
                <el-tab-pane
                  class="tabPane"
                  v-for="(item, index) in openedTabs"
                  :key="index"
                  :label="item.title"
                  :name="item.menuId"
                  :closable="item.menuId !== homeTabMenuId"
                >
                <span slot="label" >
                {{item.title}}<el-badge :value="hascall" v-if="item.menuId=='3-1' && hascall"/>
                   
                   </span>
                  <div class="content">
                    <component
                      :is="item.components[item.components.length - 1].path"
                      v-if="item.components.length"
                    ></component>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="tabOperate">
              <el-dropdown trigger="click" placement="bottom-start" >
                <!--<i class="el-icon-tickets"></i>-->
                <i class="el-icon-more"></i>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="addFavorite" 
                    >收藏</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="enterFullScreen" 
                    >全屏</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="$tab.closeAll"
                    >关闭全部</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="$tab.closeOthers"
                    >关闭其他标签</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <!--右侧 结束-->
      </div>
      <!--主框架 结束-->
    </div>
    <div
      v-show="!isPc && showMenu"
      class="popContainer"
      @click="showMenu = !showMenu"
    ></div>
    <el-button type="danger" size=mini icon="el-icon-close" class="exitFullScreen"
              @click="exitFullScreen" v-if="fullScreen" ></el-button>
  </div>
  
  <div v-else>

<!-- <el-button type="text" disabled size="mini" v-loading.fullscreen.lock="isLoading"
              ></el-button> -->
  </div>
</template>
<script>
import config from "./MainConf";
import QRCode from "qrcode";
import { Base64 } from "js-base64";
import screenfull from "screenfull";
const elementResizeDetectorMaker=require("element-resize-detector");
export default {
  data() {
    return {
      isWideScreen: true,
      isPc: false,
      config: config, // 全局配置
      showMenu: true, // 是否显示左侧菜单
      fullScreen: false, // 是否全屏
      searchText: "", // 搜索框里的文字
      menuList: [], // 复制一份菜单列表
      spreadedMenus: [], // 要展开的菜单列表
      //CustName: "商家名称", //商家
      //OperatorMan: "请登陆", //请登陆
      //Mobile: "", //电话
      //Logined: "", //是否登录
      favorite: [], //保存收藏
      isScanLogin: true, //是否扫码登陆
      isLoading: true, //是否扫码登陆
      tempToken:"",
      Mobile: "",
      userToken:"",
      LoginInfo: {
        Logined: "",
        OperatorMan: "",
        vcode: "",
        password:"",
        Operator:"瀛海简介预约订气",  
      },
      //hascall:1,
    };
  },
  computed: {
    hascall(){
      return this.$store.getters.GetHasCall;
    },
    // 当前 tab 项的 name
    currentTabIndex() {
      return this.$store.getters.GetCurrentTabIndex;
    },
    // 打开的页签列表
    openedTabs() {
      return this.$store.getters.GetOpenedTabs;
    },
    // 主页 tab 的 menuId
    homeTabMenuId() {
      return this.$store.getters.GetHomeTabMenuId;
    },
  },
  watch: {
    searchText() {
      this.filterMenu();
    },
    hascall(){
      //this.hascall=this.$store.getters.GetHasCall;
    }
  },
  methods: {
    //登陆方式
    changeLogin(){
      //var isScanLogin=this.isScanLogin;
      this.isScanLogin=!this.isScanLogin;
      localStorage.setItem("isPassLogin",this.isScanLogin?"":"1");
      this.getLoginQrcode();
      // this.tempToken= new Date().getTime() + Math.random().toString(36).substr(2);
      // console.log(this.tempToken);
    },
    getLoginQrcode(){
      if (!this.isScanLogin || this.LoginInfo.Logined!="No"){
        return;
      }
      let data = {
        lx: "getscantoken"
      };
      this.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            let canvas = document.getElementById("canvas");
            QRCode.toCanvas(canvas, res["ShortUrl"], function (error) {
              if (error) {
                console.log(error);
                console.log(res["ShortUrl"]);
                return;
              }
            });
            this.tempToken=res.temptoken
            console.log("this.tempToken",this.tempToken);
             setTimeout(() => {
                this.Again();
             }, 3000);
          } else {
            this.tempToken="";
            this.$message.error(res["message"]);
          }
        },
        (failure) => {
          this.$message.error(failure);
        }
      );
    },
    //我要注册restPwd
    regMobile() {
      let that = this;
      let mobile = that.Mobile;
      if (!that.$api.checkMobile(mobile)) {
        that.$message.error("手机号有误");
        return;
      }
      that.LoginInfo.Mobile=mobile
      localStorage.setItem("LoginInfo", JSON.stringify(that.LoginInfo));
      let data = {
        lx: "registermobile",
        sqlone: mobile,
        sqltwo: 'login'
      };
      that.$api.post(
        "",
        data,
        (success) => {
          that.$message.success(success["message"]);
        },
        (failure) => {
          that.$message.error(failure);
        }
      );
    },
    //重置密码
    restPwd() {
      let that = this;
      let mobile = that.Mobile;
      if (!that.$api.checkMobile(mobile)) {
        that.$message.error("手机号有误");
        return;
      }
      that.LoginInfo.Mobile=mobile
      localStorage.setItem("LoginInfo", JSON.stringify(that.LoginInfo));
      that.$confirm("真的要重置密码吗?", "确认操作", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            lx: "resetpassword",
            sqlone: mobile,
            sqltwo: 'reset'
          };
          that.$api.post(
            "",
            data,
            (success) => {
              that.$message.success(success["message"]);
              if (success["status"] !== "1") {
                //that.LoginInfo.Mobile = mobile;
              }
            },
            (failure) => {
              that.$message.error(failure);
            }
          );
        })
        .catch(() => {});
    },
    beginLogin() {
      let that = this;
      let mobile = that.Mobile;
      if (!that.$api.checkMobile(mobile)) {
        that.$message.error("手机号有误，11位的数字");
        return;
      }
      localStorage.setItem("Mobile", mobile);
      let vcode =that.$api.myFilter(that.LoginInfo.vcode);
      if (vcode.length < 4 || vcode.length>8) {
        that.$message.error("输入有误，密码/验证码是4-8位");
        return;
      }
      let data = {
        lx: "beginlogin",
        sqlone: mobile,
        sqltwo: vcode,
      };
      that.$api.post("",data,
        (res) => {
          if (res["status"] == "1") {
            sessionStorage.setItem("userToken",res.usertoken);
            that.swapToken()
          } else {
            that.$message.error(res["message"]);
          }
        },
        (failure) => {
          that.$message.error(failure);
        }
      );
    },
    ExitLogin() {
      this.LoginInfo.Logined = "No";
      //this.isScanLogin=false;
      this.Mobile=localStorage.getItem("Mobile")||"";
      sessionStorage.setItem("LoginInfo", JSON.stringify(this.LoginInfo));
      sessionStorage.setItem("userToken","res46757usertoken563346");
      this.getLoginQrcode();
      //this.GetToken();
    },
    // 显示/隐藏 主菜单
    showHideMenu() {
      this.showMenu = !this.showMenu;
    },
    //添加到收藏夹
    addFavorite() {
      let item=this.$tab.info();
      let itemn={title: item.title, component: item.components[0].path, menuId: item.menuId};
      if (item.menuId===this.homeTabMenuId) return;
      let favorite = this.favorite.filter(i => i.menuId !== itemn.menuId)
      favorite=[itemn,...favorite]
      this.favorite=favorite
      localStorage.setItem("favorite",JSON.stringify(this.favorite))
    },
    // 全屏
    enterFullScreen() {
      //这个控件需安装cnpm install --save-dev screenfull@5.1.0
      let element = this.$refs["Lright"];
      screenfull.toggle(element);
      this.fullScreen = true;
    },
    // 退出全屏
    exitFullScreen() {
      this.fullScreen = false;
      screenfull.exit();
    },
    // 监听 ESC 按键
    listenKeyBoardEvent() {
      let that = this;
      // 监听 ESC 按键
      window.onresize = function () {
        
      };
    },
    // 刷新组件
    reFreshTab() {
      let item=this.$tab.info();
      let c = item.components;
      item.components = [];
      this.$nextTick(() => {
        item.components = c;
      });
    },
    // 打开 页签
    openTab(item) {
      if (!this.isPc) {
        this.showMenu = false;
      }
      this.$tab.open(item);
      this.initializeMemu();
    },
    // 搜索菜单
    filterMenu() {
      let that = this;
      let operators=that.LoginInfo.Operator
      this.spreadedMenus = [];
      let keyword = that.searchText;
      let menu = JSON.parse(JSON.stringify(that.config.menu));
      let newMenu = menu.filter((item) => {
        item.sub = item.sub.filter((i) => {
          return i.title.indexOf(keyword) > -1;
        });
        return operators.indexOf(item.title) > -1 && item.sub.length > 0 && that.spreadedMenus.push(item.menuId);
      });
      that.menuList = newMenu;
    },
    initializeMemu() {
      let operators=this.LoginInfo.Operator
      let menu = JSON.parse(JSON.stringify(this.config.menu));
      let newMenu = menu.filter((item) => {
          return operators.indexOf(item.title) > -1;
      });
      this.menuList = newMenu;
    },
    listenHistory() {
      window.addEventListener("hashchange", (e) => {
        let url = location.href;
        let indexOfSharp = url.indexOf("#");
        if (indexOfSharp > 0 && url.length>indexOfSharp+10 && url.substr(indexOfSharp + 1,1)==="/") {
          indexOfSharp =indexOfSharp +1
        }
        if (indexOfSharp > 0 && url.length > indexOfSharp + 20) {
          let hash = url.substr(indexOfSharp + 1);
          let tab = JSON.parse(Base64.decode(hash));
          if (tab.fromHistory) return;
          tab.fromHistory = true;
          this.$tab.open(tab);
        }
      });
    },
    removeFavorite(item) {
      this.favorite = this.favorite.filter((i) => i.menuId !== item.menuId);
      localStorage.setItem("favorite", JSON.stringify(this.favorite));
    },
    swapToken() {
      var that=this;
      let tokencode = sessionStorage.getItem("userToken")||"";
      that.mobile="token:"+tokencode;
      console.log("初始tokencode",tokencode);
      if (!tokencode || tokencode==="undefined") {
        //sessionStorage.setItem("userToken",'absaadqrqrffg2235ryuryu43');
        that.LoginInfo.Logined = "No";
      }
      let data = {
        lx: "swaptoken",
      };
      that.$api.post("",data,
        (res) => {
          if (res["status"] === "1") {
            that.LoginInfo.Logined = "Yes";
            that.Mobile = res.mobile;
            that.LoginInfo.vcode = "";
            that.LoginInfo.Mobile = res.mobile;
            that.LoginInfo.OperatorMan = res.Contact;
            that.LoginInfo.Operator = "瀛海简介预约订气";
            that.LoginInfo.login_source=res.login_source;
            that.LoginInfo.avatarUrl= res.avatarUrl;
            that.LoginInfo.isbandwx= res.isbandwx;
            if (res.m_uc3) that.LoginInfo.Operator +="呼叫中心";
            if (res.m_manage) that.LoginInfo.Operator +="综合查询";
            if (res.m_stationer || res.m_worker) that.LoginInfo.Operator +="综合查询";
            if (res.scene) that.LoginInfo.Operator +="实时概览";
            sessionStorage.setItem("LoginInfo", JSON.stringify(that.LoginInfo));
            sessionStorage.setItem("userToken",res.usertoken);
            console.log("LoginInfo.Operator",that.LoginInfo,res.usertoken,that.Mobile)
            that.initializeMemu();
            setTimeout(() => {
              that.initMemuCollapse()
            }, 1500);
            
          }else{
            that.LoginInfo.Logined = "No";
            sessionStorage.setItem("LoginInfo", JSON.stringify(that.LoginInfo));
          }
          console.log(res)
        },
        (failure) => {}
      );
      
    },
    Again() {
      if (!this.tempToken || !this.isScanLogin || this.LoginInfo.Logined!="No"){
        return;
      }
      let data = {
        lx: "loginverifytoken",
        sqlone: this.tempToken
      };
      this.$api.post(
        "",
        data,
        (res) => {
          if (res["status"] === "1004" || res["status"] === "1006") {
            this.$message.error(res["message"]);
          } else if (res["status"] === "1") {
              sessionStorage.setItem("userToken",res.usertoken);
              this.swapToken();
              return;
          } else {
            setTimeout(() => {
              this.Again();
            }, 1500);
          }
        },
        (failure) => {}
      );
    },
    initMemuCollapse() {
      let screenWidth = document.body.clientWidth;
      if (
        (screenWidth <= 620 && this.isWideScreen) ||
        (screenWidth > 620 && !this.isWideScreen)
      ) {
        this.isWideScreen = !this.isWideScreen;
      }
      let native;
      let p = navigator.platform;
      let u = navigator.userAgent;
      let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
      let is_ios = u.indexOf(" Mac OS")>-1 || u.indexOf("iPhone") > -1  || u.indexOf("Mobile") > -1;
      if (is_android || is_ios) {
        this.isPc = false;
      } else if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {
        this.isPc = true;
      } else {
        this.isPc = false;
      }
      if (!this.isPc && this.showMenu) {
        this.showMenu = this.isPc;
      }
      
      if (this.$refs.tabBar){
        console.log(this.isPc,'kkkk')
        this.$store.commit("SettabBarWH",[this.$refs.tabBar.offsetWidth,this.$refs.tabBar.offsetHeight-40,this.isPc]);
      }
    },
    listenTotabBar(){
      //const _this = this;
      //console.log("listenTotabBar")
      //if (!_this.$refs.tabBar) return;
      //const erd = elementResizeDetectorMaker();
      //erd.listenTo(_this.$refs.tabBar, element => {
      //  _this.$nextTick(() => {
        //this.initMemuCollapse();  
                     //console.log(element.offsetWidth,element.offsetHeight);       
      //  });
      //});
      //_this.$store.commit("SettabBarWH",[_this.$refs.tabBar.offsetWidth,_this.$refs.tabBar.offsetHeight,_this.isPc]);
      //console.log("listenTotabBar","第一次运行"+_this.$refs.tabBar.offsetWidth);
    },
  },
  created() {
    let isPassLogin=localStorage.getItem("isPassLogin") || "";
    if (isPassLogin) {this.isScanLogin=false;}
    //let logininfo = JSON.parse(sessionStorage.getItem("LoginInfo")) || "";
    //if (logininfo) {
      //this.LoginInfo = logininfo;
      //this.LoginInfo.vcode = "";
    //}
    let o=this.$api.getCookie("userToken");
    if (o) {
      sessionStorage.setItem("userToken",o);
      this.LoginInfo.Logined="Yes"
      this.$api.delCookie("userToken");
    }
    this.Mobile=localStorage.getItem("Mobile")||"";
    this.swapToken();
    this.$tab.reShow();
    this.listenKeyBoardEvent();
    //this.initializeMemu();
    //this.initMemuCollapse();
    this.listenHistory();
    //if (this.LoginInfo.Logined !== "Yes") {
    //} else {
    //  this.CustName = sessionStorage.getItem("CustName");
    //  this.OperatorMan = sessionStorage.getItem("OperatorMan");
    //  this.Mobile = sessionStorage.getItem("Mobile");
    //}
    let favorite = JSON.parse(localStorage.getItem("favorite"));
    if (favorite) this.favorite=favorite;
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
    
  },
  mounted() {
    window.addEventListener('resize', ()=>{
      this.initMemuCollapse();
    });

    setTimeout(() => {
    this.getLoginQrcode();
    console.log("getLoginQrcode")
    //this.initMemuCollapse();
    }, 400);
    
  },
  beforeDestroy() {
    this.tempToken="";
  },
};
</script>

<!--公共样式-->
<style>
/* @import "~@/Styles/cover.css";
@import "~@/Styles/common.css";
@import "~@/Styles/iconfont/iconfont.css"; */
</style>
<style src = "../Styles/cover.css"></style>
<style src = "../Styles/common.css"></style>
<style src = "../Styles/iconfont/iconfont.css"></style>

<style>
  .el-dialog{
      width:300px !important;;
      }
</style>
<style scoped>
.login {
  width: 380px;
  height: 340px;
  text-align: center;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.input {
  width: 190px;
}
.button {
  width: 100px;
  margin-left: 20px;
}
.loginBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
}
#canvas {
  margin-top: -15px;
}
#canvaswx {
  margin-top: -15px;
}
</style>
<!--私有样式-->
<style scoped>
/*总容器*/
.indexWrap {
  min-width: 450px;
}
/*顶部栏*/
.topWrap {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  background: #071526;
  color: #fff;
}

/*头部logo*/
.topLogo {
  width: 200px;
  height: 100%;
  background: #85c1fd;
  /* border-right: 1px solid #53a9ff; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
}

.topLogo-img {
  height: 40px;
}
.topLogoText {
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
}

/*应用选择*/
.appName {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 64px;
  background: #071526;
  /* background: #399CFF; */
  color: #fff;
  /* border-right: 1px solid #53a9ff; */
}

.appName:hover {
  background: #53a9ff;
}

/*当前项目名称*/
.currentProjectName {
  padding-left: 15px;
  line-height: 64px;
  font-size: 16px;
}
/*个人信息*/
.userInfo:hover {
  background: #53a9ff;
}

.userInfo {
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.middleWrap {
  width: 100%;
  height: calc(100vh - 64px);
  overflow: hidden;
  display: flex;
  position: relative;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
}
/*主菜单*/
.wleft {
  width: 200px;
}
.mleft {
  width: 64px;
}
.left {
  width: 200px;
  height: 100%;
  display: block;
  /*background: #fff;*/
  background: #071526;
  /*border-right: 1px solid #e6e6e6;*/
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  position: relative;
  box-sizing: border-box;
  flex-shrink: 0;

  -ms-overflow-style: none;

  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  overflow: auto;
  /* -ms-scroll-snap-type: proximity; */
}
.fleft {
  width: 200px;
  height: 70%;
  display: float;
  /*background: #fff;*/
  background: #071526;
  /*border-right: 1px solid #e6e6e6;*/
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  position: absolute;
  box-sizing: border-box;
  flex-shrink: 0;
  z-index: 900;
  -ms-overflow-style: none;

  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  overflow: auto;
}
.left::-webkit-scrollbar {
  display: none;
}

/*菜单显示/隐藏 动画*/
.left-enter-active,
.left-leave-active {
  transition: width 0.3s;
}

.left-enter,
.left-leave-to {
  width: 0px;
}
/*菜单显示/隐藏 动画*/
.fleft-enter-active,
.fleft-leave-active {
  transition: height 0.3s;
}

.fleft-enter,
.fleft-leave-to {
  height: 0px;
}

/*搜索和收藏*/
.searchAndFavor {
  padding: 8px 5px 0 5px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  /*border-bottom: 1px solid #e6e6e6;*/
  background: #163b65;
  
  height: 46px;
}

.searchiIcon {
  font-size: 20px;
  line-height: 28px;
}

.myFavor {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #2b5992;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.myFavor:hover {
  background: #3d76bc;
}

.myFavorIcon {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
}
.exitFullScreen {
  top: 10px;
  left: calc(100vw - 65px);
  position: fixed;
  z-index: 991;
}
/*右侧布局*/
.right {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  /* flex-shrink: 1;
    border-top: 1px solid #e6e6e6;  */
}
.fullRight {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: #fff;
  /*border-top: 1px solid #e6e6e6;*/
  z-index: 990;
}

.fullRight .content {
  width: 100vw;
  height: 100vh;
  overflow-y:auto;
  /* overflow-y: scroll; */
}

.tabWrap {
  /* position: relative; */
  width: 100%;
}

.tabBar {
  /* background: #F5F7FA;0680F9 */
  /* background: #0680F9; */
  padding: 0px;
  border: 0px;
  /* box-sizing: border-box; */
}

/*以下2个样式,是覆盖 element 下拉菜单的默认样式*/
.elDropdownMenu {
  margin-top: 0 !important;
  padding: 3px 0;
}

/*没有选中的 tab 标签 要隐藏下拉菜单*/
.hiddenDropDown {
  display: none !important;
}

.elDropdownItem:hover {
  background-color: #fff !important;
}

.tabOperate {
  position: absolute;
  width: 30px;
  height: 23px;
  right: 5px;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #e4e7ed;
}

.tabDropdown {
  display: flex;
  align-items: center;
}

.tabDropdown i {
  font-size: 20px;
  margin-right: 5px;
}

.tabDropdownLine {
  width: 1px;
  height: 16px;
  background: #e4e7ed;
}

.tabDropdownRefresh {
  color: #606266;
  font-size: 14px;
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.tabDropdownRefresh:hover {
  color: #399cff;
}

.tabDropdownFavor {
  color: #606266;
  font-size: 14px;
  padding-left: 10px;
}

.tabDropdownFavor:hover {
  color: #399cff;
}
/* calc(100vh - 130px); */
.content {
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y:auto;
  /* overflow-y: scroll; */
  box-sizing: border-box;
}
</style>
<style>
.tabIcon {
  font-size: 14px;
  line-height: 32px;
  padding: 0 5px;
}
.MenuIcon {
  width: 30px !important;
  height: 18px !important;
  line-height: 32px !important;
}
.svgMenu {
  width: 16px !important;
  height: 16px !important;
  line-height: 20px !important;
  fill: #e6e6e6;
}
.el-tabs__header {
  background: #0680f9 !important;
  padding-top: 8px !important;
  border: none !important;
}
.el-tabs__item {
  padding: 0 8px !important;
  height: 32px !important;
  line-height: 32px !important;
  border-left: 8px solid #399cff !important;
  border-top: 0px solid #000cff !important;
  border-bottom: 0px solid #000cff !important;
  border-right: 0px solid #000cff !important;
  color: #000 !important;
}
.el-tabs__item .is-active {
  color: #ff0000 !important;
}
.el-tabs__content {
  padding: 1px !important;
}
.el-tabs--border-card {
  border: none !important;
}
.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
  z-index: 890;
  display: block;
}
</style>